/* Palette de couleurs */
:root {
  --color-primary: #002333; /* Bleu royal */
  --color-secondary: #61CE70; /* Rose vif */
  --color-accent: #A8E6CF; /* Vert lime */
  --color-light-grey: #a3a3a3; /* Vert lime */
  
  --color-neutral-light: #ededed; /* Blanc pur */
  --color-neutral-dark: #4A4A4A; /* Gris foncé */
  
  --color-success: #27AE60; /* Vert succès */
  --color-warning: #F1C40F; /* Jaune safran */
  --color-error: #E74C3C; /* Rouge brique */
  --color-info: #3498DB; /* Bleu ciel */
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.clear {
  float: none;
  clear: both;
  margin: 0;
  padding: 0;
  width: 0;
  height: 0;
  line-height: 0;
  border: 0;
  font-size: 0;
  outline: 0;
  overflow: hidden;
}

/* old */

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  zoom: 1;
}
html{
  width: 100%;
  height: 100%;
}
body{
  width: 100%;
  height: 100%;
  background: var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  text-align: center;
}
h1 {
  display: none;
}
img {
  width: 100%;
  max-width: 350px;
  height: auto;
}